import { useEffect, useState } from "react";
import styled from "styled-components";
import uwork from "./uwork";

const Output = styled.pre`
  flex: 1 1 50%;
  background: #fff;
  margin: 0;
  overflow: scroll;

  hr {
    border: none;
    border-top: 2px dotted #ddd;
    margin: 0;
  }
`;

const Blue = styled.span`
  color: #0000ff;
`;

const Green = styled.span`
  color: #098658;
`;

const Brown = styled.span`
  color: #a31515;
`;

const Pink = styled.span`
  color: #e300aa;
`;

const Red = styled.span`
  color: red;
`;

const Gray = styled.span`
  color: #666;
`;

const Msg = styled.div`
  padding: 10px;
`;

const Err = styled.div`
  padding: 10px;
  background: #ffc;
`;

const Fatal = styled.div`
  padding: 10px;
  background: #fbb;
`;

const getTracePosition = (e) => {
  return "    (" + e.stack.split("\n")[0].split(":").slice(-2).join(":") + ")";
};

uwork.timeout = 1000;

const clean = (arg, level = "") => {
  if (typeof arg === "boolean") return <Blue>{arg ? "true" : "false"}</Blue>;
  if (typeof arg === "number") return <Green>{arg}</Green>;
  if (typeof arg === "string") return <Brown>"{arg}"</Brown>;
  if (typeof arg === "object") {
    const left = Array.isArray(arg) ? "[" : "{";
    const right = Array.isArray(arg) ? "]" : "}";

    if (arg instanceof Error) {
      return [
        <Gray>(Error) </Gray>,
        <Red>{arg.message}</Red>,
        <Gray>{getTracePosition(arg)}</Gray>,
      ];
    }

    if (!Object.entries(arg).length || !/^[[{]/.test(JSON.stringify(arg))) {
      const type = arg instanceof Error ? "Error" : arg.constructor.name;
      return [<Gray>({type}) </Gray>, JSON.stringify(arg)];
    }

    if (JSON.stringify(arg) === "{}") return "{}";
    if (JSON.stringify(arg) === "[]") return "[]";

    if (!Object.entries(arg).length || !/^[[{]/.test(JSON.stringify(arg))) {
      return [<Gray>({arg.constructor.name}) </Gray>, JSON.stringify(arg)];
    }

    if (JSON.stringify(arg).length < 20) {
      if (Array.isArray(arg)) {
        return [
          `[`,
          arg.map((it, i, all) =>
            [clean(it, ""), i !== all.length - 1 ? ", " : ""].flat()
          ),
          `]`,
        ];
      } else {
        return [
          `{ `,
          Object.entries(arg).map(([key, value], i, all) =>
            [
              <Pink>{key}</Pink>,
              ": ",
              clean(value, ""),
              i !== all.length - 1 ? ", " : "",
            ].flat()
          ),
          ` }`,
        ];
      }
    }

    if (Array.isArray(arg)) {
      return [
        `[`,
        Object.entries(arg).map(([k, v], i, all) => [
          <br />,
          level,
          "  ",
          clean(v, level + "  "),
          i !== all.length - 1 ? "," : "",
        ]),
        <br />,
        level,
        `]`,
      ].flat();
    } else {
      return [
        `${left} `,
        Object.entries(arg).map(([k, v], i, all) => [
          <br />,
          level,
          "  ",
          <Pink>{k}</Pink>,
          ": ",
          clean(v, level + "  "),
          i !== all.length - 1 ? "," : "",
        ]),
        <br />,
        level,
        `${right}`,
      ].flat();
    }
  }
  if (typeof arg === "function") {
    return [
      /^\s*function/.test(arg.toString()) ? (
        <Blue>function </Blue>
      ) : (
        <Gray>(Function) </Gray>
      ),
      <Pink>{arg.name}</Pink>,
      arg.name ? " " : "",
      arg
        .toString()
        .replace(/^function/, "")
        .replace(/^\s*[a-zA-Z0-9]*\s*/, ""),
    ];
  }
  if (typeof arg === "symbol") {
    return [<Gray>({arg.constructor.name}) </Gray>, arg.toString()];
  }
  return null;
};

const runCode = async (code) => {
  try {
    const log = console.log;
    const err = console.error;
    const out = [];
    try {
      // Longer timeout for expected async code
      const isAsync = /(async|await)/.test(code);
      uwork.timeout = isAsync ? 10000 : 1000;

      const runtime = uwork(function myfn(code) {
        const log = console.log;
        const err = console.error;
        const out = [];
        console.log = (...args) => out.push({ log: args });
        console.error = (...args) => out.push({ error: args });
        // eslint-disable-next-line
        eval(code);
        console.log = log;
        console.error = err;
        return out;
      });

      const logs = await runtime(JSON.stringify(code));
      for (let { error, log } of logs) {
        if (error) {
          out.push([
            ...error
              .map((arg) => clean(arg))
              .map((arg) => [<Err>{arg}</Err>])
              .flat(),
            <hr />,
          ]);
        } else {
          out.push([
            ...log
              .map((arg) => clean(arg))
              .map((arg) => [<Msg>{arg}</Msg>])
              .flat(),
            <hr />,
          ]);
        }
      }
    } catch (error) {
      console.log("BAD EVAL");
      out.push(
        <Fatal>
          <Gray>(Uncaught Error) </Gray>
          <Red>{error.message}</Red>
          <Gray>{getTracePosition(error)}</Gray>
        </Fatal>
      );
    }
    console.log = log;
    console.error = err;
    return out;
  } catch (error) {
    return "";
  }
};

export default function Runner({ code }) {
  const [out, setOut] = useState(null);
  useEffect(() => {
    runCode(code).then(setOut);
  }, [code]);
  return <Output>{out}</Output>;
}
