import Router, { Route, Switch } from "crossroad";
import styled from "styled-components";

import Style from "./Style";

import Editor from "./Editor";
import Store, { useSelector } from "statux";

const Page = styled.div`
  width: calc(100vw - 10px);
  height: calc(100vh - 10px);
  display: flex;
  padding: 5px;

  ${(p) =>
    p.horizontal
      ? `
    & > :first-child {
      border-radius: 0 0 0 10px;
    }
    & > :last-child {
      border-left: 2px solid #ddd;
      margin-top: 30px;
      border-radius: 0 10px 10px 0;
    }
  `
      : `
    flex-direction: column;
    & > :first-child {
      border-radius: 0;
      overflow: none;
    }
    & > :last-child {
      border-top: 2px solid #ddd;
      border-radius: 0 0 10px 10px;
    }
  `}
`;

const PageWrapper = ({ children }) => {
  const horizontal = useSelector("horizontal");
  localStorage._horizontal = JSON.stringify(horizontal);
  return <Page horizontal={horizontal}>{children}</Page>;
};

const horizontal = localStorage._horizontal || true;

export default function App() {
  return (
    <Store horizontal={horizontal}>
      <PageWrapper>
        <Style />
        <Router>
          <Switch redirect="/file/default">
            <Route path="/file/:file" component={Editor} />
          </Switch>
        </Router>
      </PageWrapper>
    </Store>
  );
}
