import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  html,
  body {
    margin: 0;
    
    font-size: 16px;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: #aaa;
  }
`;
