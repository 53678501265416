import { useEffect, useRef } from "react";
import { editor as monaco } from "monaco-editor";
import styled from "styled-components";
import { useSelector } from "statux";

import Nav from "./Nav";
import Runner from "./Runner";
import useLocalState from "./use-local-storage";
import defaultCode from "./defaultCode";

const Wrapper = styled.div`
  flex: 0 0 50%;
  overflow: scroll;
`;

const Code = styled.div`
  background: #fff;
  height: 100%;
`;

const defOptions = {
  language: "javascript",
  fontSize: "16px",
  padding: "20px",
  wrappingColumn: 80,
};

export default function Editor({ file = "default" }) {
  const ref = useRef();
  const [value, setValue] = useLocalState(file, defaultCode);
  const horizontal = useSelector("horizontal");

  useEffect(() => {
    const editor = monaco.create(ref.current, { ...defOptions, value });

    editor.onDidChangeModelContent((e) => {
      const newValue = editor.getModel().getValue();
      setValue(newValue);
    });

    return () => editor.dispose();

    // Cannot have this being destroyed every char type, so
    // only reset it when the page changes
    // eslint-disable-next-line
  }, [file, horizontal]);

  return (
    <>
      <Wrapper>
        <Nav />
        <Code ref={ref}></Code>
      </Wrapper>
      <Runner code={value} />
    </>
  );
}
