import { useEffect } from "react";
import styled from "styled-components";
import { useParams, useUrl } from "crossroad";
import { X } from "react-feather";
import { useStore } from "statux";

import defaultCode from "./defaultCode";

const Menu = styled.nav`
  display: block;
  height: 30px;
  display: flex;
  gap: 10px;
`;

const File = styled.a`
  display: inline-flex;
  height: 30px;
  line-height: 30px;
  color: inherit;
  text-decoration: none;
  padding: 0 10px;
  background: #eee;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -3px 3px -3px #0005 inset;
  cursor: pointer;
  transition: all 0.2s ease;
  align-items: center;

  &:hover {
    background: white;
    box-shadow: 0 -3px 3px -3px #0000 inset;
  }

  ${(p) =>
    p.active &&
    `
      background: white;
      box-shadow: 0 -3px 3px -3px #0000 inset;`}

  ${(p) =>
    p.add &&
    `
    height: 24px;
    line-height: 24px;
    margin-bottom: 3px;
    border-radius: 5px;
    box-shadow: none;
  `}
`;

const Icon = styled.div`
  display: inline-block;
  height: 16px;
  line-height: 16px;
  margin-left: 10px;
  margin-right: -5px;
  border-radius: 5px;
  padding: 3px;
  transition: all 0.2s ease;

  &:hover {
    background: #fcc;
    color: red;
  }
`;

const Clickable = styled.div`
  background: #eee;
  box-sizing: border-box;
  height: 26px;
  width: 26px;
  line-height: 22px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
  transform: scale(0.9) rotate(${(p) => (p.horizontal ? 0 : 90)}deg);
  transition: all 0.15s ease;

  :hover {
    background: #fff;
    transform: scale(1) rotate(${(p) => (p.horizontal ? 0 : 90)}deg);
  }
`;

export default function Nav() {
  const { file } = useParams("/file/:file");
  const [, setUrl] = useUrl();

  const files = Object.keys(localStorage)
    .sort()
    .filter((k) => !k.startsWith("_"));
  if (!files.includes("default")) {
    files.push("default");
  }

  useEffect(() => {
    document.title = file + " - Javascript Makeup";
  }, [file]);

  const addFile = () => {
    const name = window.prompt("New file name?").replace(/\.js/, "");
    localStorage.setItem(name, JSON.stringify(defaultCode));
    setUrl("/file/" + name);
  };

  const deleteFile = (name) => {
    const yes = window.confirm(
      `Delete "${name}.js"?\n\nYou are about to delete the file named "${name}.js", are you sure?`
    );
    if (!yes) return;
    localStorage.removeItem(name);
    // Very strange bug here 🤷‍♂️
    setTimeout(() => {
      const first = Object.keys(localStorage).sort()[0] || "default";
      setUrl("/file/" + first);
    }, 100);
  };

  const [horizontal, setHorizontal] = useStore("horizontal");

  return (
    <Menu>
      {files.map((name, i) => (
        <File href={`/file/${name}`} active={name === file}>
          {name}
          <Icon onClick={() => deleteFile(name)}>
            <X size={16} />
          </Icon>
        </File>
      ))}
      <File add onClick={addFile}>
        + Add File
      </File>
      <Clickable
        horizontal={horizontal}
        onClick={() => setHorizontal(!horizontal)}
      >
        ◨
      </Clickable>
    </Menu>
  );
}
