import { useCallback, useEffect, useState } from "react";

const defOptions = {
  serializer: JSON.stringify,
  parser: JSON.parse,
  logger: console.log,
};

function useLocalStorage(key, defaultValue, options = defOptions) {
  // Let's check e.g. 'serializer' is properly memoized in all cases:
  // 1. undefined => defOptions is a global var => reused
  // 2. {} => serializer is reusing the global fn => reused
  // 3. { parser: fn } => serializer is reusing the global fn => reused
  // 4. { serializer: fn } => serializer changes, using new one => updated
  // Same for the other variables
  const { serializer, parser, logger } = {
    ...defOptions,
    ...options,
  };

  // The parser and logger are supposed to change very infrequently since
  // they are memoized above; however the key and default value are not
  // memoized in any way so we take them as params and expect the caller
  // of this function to properly depend on them
  const getValue = useCallback(
    (key, defaultValue) => {
      if (typeof window === "undefined") return defaultValue;
      try {
        const item = window.localStorage.getItem(key);
        const res = item ? parser(item) : defaultValue;
        return res;
      } catch (e) {
        logger(e);
        return defaultValue;
      }
    },
    [parser, logger]
  );

  const value = getValue(key, defaultValue);
  const [, updateFn] = useState(null);
  const update = () => updateFn({});

  const setValue = useCallback(
    (newValue) => {
      if (typeof newValue === "undefined") {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, serializer(newValue));
      }
      update();
    },
    [key, serializer]
  );

  // Only for DIFFERENT page's changes
  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleStorageChange = (e) => {
      if (e.key !== key || e.storageArea !== window.localStorage) return;
      setValue(e.newValue ? parser(e.newValue) : defaultValue);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [key, defaultValue, setValue, parser]);

  console.log(key, value);
  return [value, setValue];
}

export default useLocalStorage;
